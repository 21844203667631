import React, { useState, useEffect, useRef, createContext } from "react";
import "../../pages/Sidebar/sidebar.css";
import "../../assets/styles/application_det.css";
import "../../assets/styles/style.css";
import {
  useCreate,
  useGetOne,
  Confirm,
  useGetIdentity,
  usePermissions,
} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import CheckIcon from "@mui/icons-material/Check";
import DialogTitle from "@mui/material/DialogTitle";
import { GetdeleteddocumentlistList } from "../DocuploadTableComponent/GetDeletedDocumentList";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { yupResolver } from "@hookform/resolvers/yup";
import ApplicationHeader from "./ApplicationHeader";
import SelectInput from "./components/inputComponents/SelectInput";
import ProofOfId from "./ProofOfId";
import NameChangeModal from "./Modals/NameChangeModal";
import ProofOfNonEmploymentSection from "./ProofOfNonEmploymentIncome";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BankStatements from "./BankStatements";
import OtherDocuments from "./OtherDocuments";
import ProofOfEmploymentIncome from "./ProofOfEmploymentIncome";
import BottomNavigation from "@mui/material/BottomNavigation";
import pencilIcon from "../../assets/images/pencilIcon.svg";
import Box from "@mui/material/Box";
import { useNotify, useDataProvider, Resource } from "react-admin";
import moment from "moment";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import AlertDialogTable from "../DocuploadTableComponent/AlertDialogTable";
import { RootState } from "../../Store/store";
import { useDispatch, useSelector } from "react-redux";
import PersonalDetailsAccordion from "./PersonalDetailsAccordion/PersonalDetailsAccordion";
import AddressAccordion from "./AddressAccordion/AddressAccordion";
import ConsentPaymentAccordion from "./ConsentPaymentAccordion/ConsentPaymentAccordion";
import ApplicantDetailsAccordion from "./ApplicantDetailsAccordion/ApplicantDetailsAccordion";
import { JSONTree } from "react-json-tree";
import SsnAccordion from "./SsnAccordion/SsnAccordion";
import { setApplicationStatus } from "../../Store/Slice/usersSlice";
import img1 from "../../assets/images/view_docs_icon.svg";
import * as Yup from "yup";

import Frame from "../../assets/images/Frame.svg";
import PrintIcon from "@mui/icons-material/Print";
import { BiSolidFileArchive, BiSolidFilePdf } from "react-icons/bi";
import { ATS_API_URL, typesofUsers } from "../../config";
import { FaBook } from "react-icons/fa";
import { dataProvidersAts } from "../../dataproviders/DataProvidersAts";
import { toast, ToastContainer } from "react-toastify";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import ShowNotesModal from "./Modals/ShowNotesModal";
import GrayStripeBar from "../common/GrayStripeBar";
import { RiDeleteBinLine } from "react-icons/ri";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DateComponent from "../Ats/DateComponent";
import AuditlogModal from "./Modals/AuditlogModal";
import { useUserRole } from "../../ContextApi/UserRoleContext";
import ProofOfIdForm from "./ProofOfIdForm";
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";
import { FaRegNoteSticky } from "react-icons/fa6";

interface RefObject {
  handleClickByRef: () => void;
  handleSubmitManual: () => void;
}

type DocumentAnalysisShowProps = {
  toggleDetails?: boolean;
  record?: any;
  index?: number,
  setForError?: any,
  tabValidation?: any[],
  totalApplicationCount?: number
};
export const ApplicantContext = createContext<any>(null);
const DocumentAnalysisShow = ({
  toggleDetails,
  record,
  totalApplicationCount = 1,
  index = 0,
  setForError = () => { },
  tabValidation = [],
}: DocumentAnalysisShowProps) => {
  const [childFormError1, setChildFormError1] = useState({});
  const [childFormError2, setChildFormError2] = useState({});
  const [childFormError3, setChildFormError3] = useState({});
  const [childFormError4, setChildFormError4] = useState({});
  const [childFormError5, setChildFormError5] = useState({});
  const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  useEffect(() => {
    if (clickCount > 0) {
      setForError(
        (isEmptyObject(childFormError1) ? false : childFormError1) ||
        (isEmptyObject(childFormError2) ? false : childFormError2) ||
        (isEmptyObject(childFormError3) ? false : childFormError3) ||
        (isEmptyObject(childFormError5) ? false : childFormError5) ||
        (isEmptyObject(childFormError4) ? false : childFormError4)
      );
    }
    else {
      setForError(true);
    }

  }, [childFormError1, childFormError2, childFormError3, childFormError4]);

  const exportRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const navigation = useNavigate();
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id = record?.Trnid || userTrnId;
  const applicant_id = record?.id;


  const [applicantInfo, setApplicantInfo] = useState({
    trnId: trn_id,
    applicantId: applicant_id,
  });
  const isOpeningForDashboard = useSelector(
    (state: RootState) => state.app.isOpeningForDashboard
  );
  const appHeaderRequired = useSelector(
    (state: RootState) => state.app.appHeader
  );

  const appFooterRequired = useSelector(
    (state: RootState) => state.app.appFooter
  );
  const [isLoadingViewApp, setIsLoading] = useState(false);
  const isGuestCard = useSelector((state: RootState) => state.app.isGuestCard);
  const { role: userRoleContext } = useUserRole();
  const userRoleRedux = useSelector((state: RootState) => state.users.role);
  const userRole = userRoleContext || userRoleRedux;
  const poidRef = useRef<RefObject>(null);
  const poincome = useRef<RefObject>(null);
  const po_other_income = useRef<RefObject>(null);
  const possn = useRef<RefObject>(null);
  const po_banking = useRef<RefObject>(null);
  const [zipDownload, setZipDownload] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [grossMonthlyPay, setGrossMonthlyPay] = useState(0);

  const handleGrossvalue = (value) => {
    setGrossMonthlyPay(value);
  };
  const schema = Yup.object({
    application_status: Yup.string().required("required").typeError("required"),
  });
  const schemaforNonDocuverusAdmin = Yup.object({
    application_status: Yup.string().nullable(),
  });
  const methods = useForm({
    resolver: yupResolver(userRole == 'Docuverus Super Admin' ? schema : schemaforNonDocuverusAdmin),
    mode: "onBlur",
  });
  const status1 = methods.getValues("application_status");
  const handleFormSubmitNext = async () => {
    setClickCount(preValue => preValue + 1);
    if (!isGuestCard) {
      let isValid_poidRef: any;
      let isValid_possn: any;
      let isValid_poincome: any;
      let isValid_po_other_income: any;
      let isValid_po_banking: any;

      if (
        sectionPermission?.data?.status[3].state == 2 ||
        sectionPermission?.data?.status[3].state == 1
      ) {
        isValid_poidRef =
          await poidRef?.current?.handleClickByRef();
        if (!isValid_poidRef) return;
      } else {
        isValid_poidRef = true;
      }

      // ssn adding
      if (
        sectionPermission?.data?.status[4].state == 2 ||
        sectionPermission?.data?.status[4].state == 1
      ) {
        isValid_possn =
          await possn?.current?.handleClickByRef();
        if (!isValid_possn) return;
      } else {
        isValid_possn = true;
      }

      if (
        sectionPermission?.data?.status[0].state == 2 ||
        sectionPermission?.data?.status[0].state == 1
      ) {
        isValid_poincome =
          await poincome?.current?.handleClickByRef();
        if (!isValid_poincome) return;
      } else {
        isValid_poincome = true;
      }
      if (
        sectionPermission?.data?.status[2].state == 2 ||
        sectionPermission?.data?.status[2].state == 1
      ) {
        isValid_po_other_income =
          await po_other_income?.current?.handleClickByRef();
        if (!isValid_po_other_income) return;
      } else {
        isValid_po_other_income = true;
      }
      if (
        sectionPermission?.data?.status[1].state == 2 ||
        sectionPermission?.data?.status[1].state == 1
      ) {
        isValid_po_banking =
          await po_banking?.current?.handleClickByRef();
        if (!isValid_po_banking) return;
      } else {
        isValid_po_banking = true;
      }

      if (
        isValid_poidRef &&
        isValid_poincome &&
        isValid_po_other_income &&
        isValid_po_banking &&
        isValid_possn
      ) {
        try {
          let callArray: any = [];
          if (proof_of_id_section)
            callArray.push(
              poidRef?.current?.handleSubmitManual()
            );
          if (proof_of_ssn_section) callArray.push(possn?.current?.handleSubmitManual(),)
          if (proof_of_income_section)
            callArray.push(
              poincome?.current?.handleSubmitManual()
            );
          if (other_income_section)
            callArray.push(
              po_other_income?.current?.handleSubmitManual()
            );
          if (bank_section)
            callArray.push(
              po_banking?.current?.handleSubmitManual()
            );
          await Promise.all(callArray);
        } catch (error) {
          notify(`Error updating sections`, {
            type: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          handleApplicantSubmitNext(trn_id);
        }
      } else {
      }
    }
    if (isGuestCard) {
      let isValid_poidRef: any;
      isValid_poidRef =
        await poidRef?.current?.handleClickByRef();
      if (!isValid_poidRef) return;
      if (isValid_poidRef) {
        try {
          let callArray: any = [];
          if (proof_of_id_section)
            callArray.push(
              poidRef?.current?.handleSubmitManual()
            );
          await Promise.all(callArray);
        } catch (error) {
          notify(`Error updating sections`, {
            type: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          handleApplicantSubmitNext(trn_id);
        }
      }
    }
  }
  const handleFormSubmit = async () => {
    setClickCount(preValue => preValue + 1);
    if (!isGuestCard) {
      let isValid_poidRef: any;
      let isValid_possn: any;
      let isValid_poincome: any;
      let isValid_po_other_income: any;
      let isValid_po_banking: any;

      if (
        sectionPermission?.data?.status[3].state == 2 ||
        sectionPermission?.data?.status[3].state == 1
      ) {
        isValid_poidRef =
          await poidRef?.current?.handleClickByRef();
        if (!isValid_poidRef) return;
      } else {
        isValid_poidRef = true;
      }

      // ssn adding
      if (
        sectionPermission?.data?.status[4].state == 2 ||
        sectionPermission?.data?.status[4].state == 1
      ) {
        isValid_possn =
          await possn?.current?.handleClickByRef();
        if (!isValid_possn) return;
      } else {
        isValid_possn = true;
      }

      if (
        sectionPermission?.data?.status[0].state == 2 ||
        sectionPermission?.data?.status[0].state == 1
      ) {
        isValid_poincome =
          await poincome?.current?.handleClickByRef();
        if (!isValid_poincome) return;
      } else {
        isValid_poincome = true;
      }
      if (
        sectionPermission?.data?.status[2].state == 2 ||
        sectionPermission?.data?.status[2].state == 1
      ) {
        isValid_po_other_income =
          await po_other_income?.current?.handleClickByRef();
        if (!isValid_po_other_income) return;
      } else {
        isValid_po_other_income = true;
      }
      if (
        sectionPermission?.data?.status[1].state == 2 ||
        sectionPermission?.data?.status[1].state == 1
      ) {
        isValid_po_banking =
          await po_banking?.current?.handleClickByRef();
        if (!isValid_po_banking) return;
      } else {
        isValid_po_banking = true;
      }

      if (
        isValid_poidRef &&
        isValid_possn &&
        isValid_poincome &&
        isValid_po_other_income &&
        isValid_po_banking
      ) {
        try {
          let callArray: any = [];
          if (proof_of_id_section)
            callArray.push(
              poidRef?.current?.handleSubmitManual()
            );
          if (proof_of_ssn_section) callArray.push(possn?.current?.handleSubmitManual(),)
          if (proof_of_income_section)
            callArray.push(
              poincome?.current?.handleSubmitManual()
            );
          if (other_income_section)
            callArray.push(
              po_other_income?.current?.handleSubmitManual()
            );
          if (bank_section)
            callArray.push(
              po_banking?.current?.handleSubmitManual()
            );
          await Promise.all(callArray);
        } catch (error) {
          notify(`Error updating sections`, {
            type: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          handleApplicantSubmit(trn_id);
        }
      } else {
      }
    }
    if (isGuestCard) {
      let isValid_poidRef: any;
      isValid_poidRef =
        await poidRef?.current?.handleClickByRef();
      if (!isValid_poidRef) return;
      if (isValid_poidRef) {
        try {
          let callArray: any = [];
          if (proof_of_id_section)
            callArray.push(
              poidRef?.current?.handleSubmitManual()
            );
          await Promise.all(callArray);
        } catch (error) {
          notify(`Error updating sections`, {
            type: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } finally {
          handleApplicantSubmit(trn_id);
        }
      }
    }
  }
  const [noteText, setNoteText] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deleteddocs, setdeleteddocs] = useState(false);
  const allButLastAreTrue = tabValidation?.slice(0, -1).every(value => value === true);
  const handleopendeleteddocs = () => {
    setdeleteddocs(true);
  };
  const handleclosedeleteddocs = () => {
    setdeleteddocs(false);
  };
  const dispatch = useDispatch();
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // get application type
  const { id, appType, roletype } = useParams();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [applicantDetails, setApplicantDetails] = React.useState<any>({
    application_id: null,
    application_status: null,
    creataed_on: null,
    email: null,
    first_name: null,
    last_name: null,
    middle_name: null,
    mobile: null,
    country_code: null,
    office_property_city: null,
    office_property_name: null,
  });



  const [subscriberData, setSubscriberData] = useState({
    CompanyName: null,
    StreetAddress: null,
    State: null,
    City: null,
    Email: null,
    NumberOfCoApplicant: null,
    LookBackPeriod: null,
  });

  // state for set a flag if data is enable/null of third Party Integration.
  const [isThirdPartyIntegration, setIsThirdPartyIntegration] = useState(false);

  useEffect(() => {
    if (applicantDetails?.archived == true && userRole == "applicant") {
      navigation("/admin/archieved", { replace: true });
    }
  }, [applicantDetails]);
  const { data: proofOfIdsData, isLoading: proofOfIdsDataLoading } = useGetOne(
    "proofOfIdsData",
    { id: trn_id },
    { enabled: trn_id != null, refetchOnWindowFocus: false }
  );



  const IncomeAPIResponse = useGetOne(
    "income",
    { id: trn_id },
    { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  );



  const { data: meta_d_response } = useGetOne('getMetaData', { id: 3, meta: { trn_id } }, { refetchOnWindowFocus: false, staleTime: 5 })

  const { data: validationCheck, isSuccess } = useGetOne("getBankValidationCheck", { id: trn_id }, { enabled: trn_id != null, refetchOnWindowFocus: false });
  const { data: validationCheckForIncome } = useGetOne("getEmployerValidationCheck", { id: trn_id }, { enabled: trn_id != null, refetchOnWindowFocus: true });
  const { data: validationCheckForID } = useGetOne("getIDValidationCheck", { id: trn_id }, { enabled: trn_id != null, refetchOnWindowFocus: false });

  const { data: bankData, isLoading: bankDocLoading, isFetching } = useGetOne(
    "bankDoc",
    { id: trn_id },
    { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  );


  const { data: otherIncomeData, isLoading: otherIncomeDocLoding } = useGetOne(
    "otherIncomeDoc",
    { id: trn_id },
    { enabled: !isGuestCard && trn_id != null, refetchOnWindowFocus: false }
  );
  const sectionPermission = useGetOne(
    "getSubscriberProfileSection_new",
    { id: trn_id },
    { refetchOnWindowFocus: false, enabled: !!trn_id }
  );

  const {
    data: getSubscriberConfigurations,
    isLoading: isLoadingSubscriberConfigurations,
  } = useGetOne(
    "get-subscriber-configurations",
    {
      id: id,
    },
    {
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );

  const {
    data: applicantData,
    isLoading: loading,
    refetch,
  } = useGetOne(
    "getApplicantDetails",
    { id: trn_id },
    {
      onSuccess: (value) => {
        setApplicantDetails(value?.Application_details);
        setSubscriberData(value?.data?.subscriber_details);
        dispatch(
          setApplicationStatus(value?.Application_details?.application_status)
        ); // storing a application_status for global used
      },
      onError: (err) => {
        notify(`Error: ${err}`, {
          type: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      refetchOnWindowFocus: false,
      enabled: trn_id != null,
    }
  );

  const appStatus = applicantData?.Application_details?.application_status;
  // 
  function getLowestStatus() {

    let employeeStatus: (number | null)[] = [];
    let bankStatus: (number | null)[] = [];


    const idStatus = proofOfIdsData?.identification_data?.ManualSOCRstatus
      ? proofOfIdsData?.identification_data?.ManualSOCRstatus
      : validationCheckForID?.SOCRStatus;

    // Fetch employee status from either `meta_d_response` or `validationCheckForIncome`
    if (IncomeAPIResponse?.data?.employment_details?.SOCRstatus || validationCheckForIncome?.other_check) {
      const employeeStatusFromIncome = IncomeAPIResponse?.data?.employment_details?.SOCRstatus
        ? Object.values(IncomeAPIResponse?.data?.employment_details?.SOCRstatus).filter((status) => status !== null && status !== undefined)
        : [];

      const employeeStatusFromValidation = validationCheckForIncome?.other_check
        ? Object.values(validationCheckForIncome?.other_check)
          .map((source) => (source as any)?.SOCRStatus)
          .filter((status) => status !== null && status !== undefined)
        : [];

      employeeStatus = employeeStatusFromIncome.length ? employeeStatusFromIncome : employeeStatusFromValidation;
    }

    // Fetch bank status from either `bankData` or `validationCheck`
    if (bankData?.banking_details?.SOCRstatus || validationCheck?.other_check) {
      const bankStatusFromBank = bankData?.banking_details?.SOCRstatus
        ? Object.values(bankData?.banking_details?.SOCRstatus).filter((status) => status !== null && status !== undefined)
        : [];

      const bankStatusFromValidation = validationCheck?.other_check
        ? Object.values(validationCheck?.other_check)
          .map((source) => (source as any)?.SOCRStatus)
          .filter((status) => status !== null && status !== undefined)
        : [];

      bankStatus = bankStatusFromBank.length ? bankStatusFromBank : bankStatusFromValidation;
    }


    const statusMapping = {
      "1": "Verified",
      "2": "Unverified",
      "3": "Further Documentation Required"
    };

    const statuses = [idStatus, ...employeeStatus, ...bankStatus]
      .filter(status => status !== null && status !== undefined)
      .map(status => statusMapping[status]);



    const lowestStatus = statuses.includes("Unverified")
      ? "Unverified"
      : statuses.includes("Further Documentation Required")
        ? "Further Documentation Required"
        : statuses.includes("Verified")
          ? "Verified"
          : null;

    return lowestStatus;
  }

  const applicantStatusKey = (appStatus == 13 || appStatus == 14 || appStatus == 15) ? appStatus : getLowestStatus()

  useEffect(() => {
    // Set application_status to null if applicantStatusKey is null
    let statusValue: any = null;
    if (applicantStatusKey !== null) {


      statusValue = (applicantStatusKey == "Further Documentation Required" || applicantStatusKey == 14) ? 14 : (applicantStatusKey == "Unverified" || applicantStatusKey == 15) ? 15 : 13;
      //statusValue = applicantStatusKey.toString()

    }
    methods.setValue("application_status", statusValue);
  }, [applicantStatusKey]);

  const { data: getlookbacks } = useGetOne(
    "getlookbacks",
    { id: record?.AppID },
    { enabled: record?.AppID != null, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (zipDownload) {
      setTimeout(() => {
        notify("Downloading documents...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
    }
  }, [zipDownload]);

  const [isDownloading, setIsDownloading] = useState(false);
  const downloadZip = async (id: string) => {
    setIsDownloading(true);
    try {

      setZipDownload(true);
      // showToastMessage();
      const res = await dataProvidersAts.downloadZip(id);
      if (res) {
        toast.success("downloading documents......", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setZipDownload(false);

    }
    finally {
      setIsDownloading(false);
    }
  };
  const location = useLocation();
  const params = useParams();
  const getlocalStorageItem: any = localStorage.getItem(
    "stateRecord" + params?.applicationId
  );
  let locationState = JSON.parse(getlocalStorageItem);
  const data = location.state || locationState;
  const { permissions } = usePermissions();

  const [create] = useCreate();
  const onClickScreeningReport = async (event, data) => {
    create(
      "getscreeningreport",
      {
        data: {
          applicantID: data?.ApplicantID,
          subscriberId: data?.SubscriberID,
        },
      },
      {
        onSuccess: (data) => {
          notify(`Screening report opened successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const formSubmitApiCall = async (id) => {
    const final_paylaod = {
      trn_id: id,
      role: "applicant",
      status: "5",
      subUrl: "/document/updateApplicationStatus",
      office_property_name: record?.Property,
    };
    try {
      await dataProvider.update("other_sections", {
        id: id,
        data: final_paylaod,
        previousData: proofOfIdsData,
      });
      navigation("/admin/success", { replace: true });
      notify(`Application submitted Successfully`, {
        type: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } catch (e) {
      notify(`Error: fail submitting application details`, {
        type: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      // setLoading(false);
    }
  }
  const navigateToPrevTab = () => {
    const currentTab = document.querySelector('#nav-tab .nav-link.active') as HTMLElement | null;
    const currentTabBody = document.querySelector('#nav-tabContent .active') as HTMLElement | null;
    const isPrevTabPresent = currentTab?.previousElementSibling as HTMLElement | null;
    if (currentTab && currentTabBody && isPrevTabPresent) {
      currentTab.classList.remove('active');
      currentTabBody.classList.remove('active');
      currentTabBody.classList.remove('show');

      const prevTab = currentTab.previousElementSibling as HTMLElement | null;
      const prevTabBody = currentTabBody.previousElementSibling as HTMLElement | null;
      if (prevTab && prevTabBody) {
        prevTab?.classList.add('active');
        prevTabBody?.classList.add('active');
        prevTabBody?.classList.add('show');
        prevTab.scrollIntoView()
      }
    }
  }
  const handleApplicantSubmitNext = (id) => {
    if (totalApplicationCount > 1) {
      // move to next tab
      const currentTab = document.querySelector('#nav-tab .nav-link.active') as HTMLElement | null;
      const currentTabBody = document.querySelector('#nav-tabContent .active') as HTMLElement | null;

      // TODO : check has next tab
      const isNextTabPresent = currentTab?.nextElementSibling as HTMLElement | null;

      if (currentTab && currentTabBody && isNextTabPresent) {
        currentTab.classList.remove('active');
        currentTabBody.classList.remove('active');
        currentTabBody.classList.remove('show');

        // 

        const nextTab = currentTab.nextElementSibling as HTMLElement | null;
        const nextTabBody = currentTabBody.nextElementSibling as HTMLElement | null;
        if (nextTab && nextTabBody) {
          nextTab?.classList.add('active');
          nextTabBody?.classList.add('active');
          nextTabBody?.classList.add('show');
          nextTab.scrollIntoView()
          handle_Admin_Submit_Next(methods.getValues());

        }

      } else {
        // TODO: check if all tabValidation is true exlude last one then submit the form
        const allButLastAreTrue = tabValidation.every(value => value === true);
        if (allButLastAreTrue) {
          // alert("PAvi " + tabValidation.includes(false));
          formSubmitApiCall(id);
        }

        else {
          /*
          
          */
          const nextTab = document.querySelector(`#nav-tab .nav-link:nth-child(${tabValidation.indexOf(false) + 1})`) as HTMLElement | null;
          const currentTabBody = document.querySelector(`#nav-tabContent .active `) as HTMLElement | null;
          const nextTabBody = document.querySelector(`#nav-tabContent .tab-pane:nth-child(${tabValidation.indexOf(false) + 1})`) as HTMLElement | null;
          if (nextTab && nextTabBody) {
            nextTab?.classList.add('active');
            currentTab?.classList.remove('active');
            currentTabBody?.classList.remove('active');
            currentTabBody?.classList.remove('show');
            nextTabBody?.classList.add('active');
            nextTabBody?.classList.add('show');
            nextTab.scrollIntoView()
            handle_Admin_Submit_Next(methods.getValues());
          }
        }
      }
    } else {
      {
        // alert("Please fill all the required fields " + tabValidation.includes(false));
        if (tabValidation.includes(false))
          formSubmitApiCall(id)
        else {
          // Todo: go to index of false in tabValidation array and scroll to that tab
          const nextTab = document.querySelector(`#nav-tab .nav-link:nth-child(${tabValidation.indexOf(false) + 1})`) as HTMLElement | null;
          const nextTabBody = document.querySelector(`.tab-pane:nth-child(${tabValidation.indexOf(false) + 1})`) as HTMLElement | null;
          if (nextTab && nextTabBody) {
            nextTab?.classList.add('active');
            nextTabBody?.classList.add('active');
            nextTab.scrollIntoView()
          }
        }
      }
    }
  };
  const handleApplicantSubmit = (id) => {
    formSubmitApiCall(id);
  };
  const [updateApplicant, updateApplicantStatus] = useCreate();

  const handle_Admin_Submit = async (values) => {

    const final_paylaod = {
      trn_id: trn_id,
      role: userRole,
      status: userRole == 'Docuverus Super Admin' ? values.application_status : "5",
      office_property_name: record?.Property,
      grossMonthlyPay: grossMonthlyPay
    };



    updateApplicant(
      "updateApplicationStatus",
      {
        data: final_paylaod,
      },
      {
        onSuccess: (data) => {
          notify(data?.records, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refetch();

          if (
            values?.application_status == "13" ||
            values?.application_status == "14" ||
            values?.application_status == "15"
          ) {

            if (isThirdPartyIntegration) {

              handledsendLeaseDocument();
            }
          }
        },
        onError: (error: any) => {
          notify(`${error.message}`, {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  //duplicate
  const handle_Admin_Submit_Next = async (values) => {

    const final_paylaod = {
      trn_id: trn_id,
      role: userRole,
      status: values.application_status ? values.application_status : "14",
      office_property_name: record?.Property,
      grossMonthlyPay: grossMonthlyPay
    };



    updateApplicant(
      "updateApplicationStatus",
      {
        data: final_paylaod,
      },
      {
        onSuccess: (data) => {
          notify(data?.records, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refetch();

          if (
            values?.application_status == "13" ||
            values?.application_status == "14" ||
            values?.application_status == "15"
          ) {

            if (isThirdPartyIntegration) {

              handledsendLeaseDocument();
            }
          }

        },
        onError: (error: any) => {
          notify(`${error.message}`, {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };
  const handledsendLeaseDocument = () => {

    const authJson = localStorage.getItem("auth") || "";
    let authObj = authJson ? JSON.parse(authJson) : {};
    if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
      delete authObj.data.permissions;
    }
    if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
      delete authObj.data.systemNotification;
    }


    const updatedAuthJson = JSON.stringify(authObj);

    const url = `${window.location.protocol}//${window.location.host}/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${trn_id +
      `?auth=` +
      encodeURIComponent(updatedAuthJson) +
      `&createdDateAsPerClientRequest=` +
      moment(applicantDetails?.creataed_on).format(
        "MM/DD/YYYY h:mm A z"
      )
      }`
    updateApplicant(
      "sendLeaseDocument",
      {
        data: {
          trn_id: trn_id,
          url: url,
        },
      },
      {
        onSuccess: (data) => { },
        onError: (error: any) => { },
      }
    );
  };
  const navigate = useNavigate();
  // Function to determine the flag value
  const hasAnyStatusOne = (objects) => {
    // Use the some method for concise and efficient checking
    return objects ? objects?.some((object) => object?.status === 1) : false;
  };

  useEffect(() => {
    if (getSubscriberConfigurations?.records) {
      let thirdPartyIntegration;
      if (
        typeof getSubscriberConfigurations?.records?.ThirdPartyIntegration !==
        "object"
      ) {
        thirdPartyIntegration = JSON?.parse(
          getSubscriberConfigurations?.records?.ThirdPartyIntegration
        );
      } else {
        thirdPartyIntegration =
          getSubscriberConfigurations?.records?.ThirdPartyIntegration;
      }
      setIsThirdPartyIntegration(hasAnyStatusOne(thirdPartyIntegration));
    }
  }, [getSubscriberConfigurations?.records]);

  // storaging trn id in session storage.
  React.useEffect(() => {
    if (id) {
      sessionStorage.setItem("trn_id", JSON.stringify(id));
    }
  }, [id]);

  const onClickDocsHandler = async (event, applicantData) => {
    console.log(record,applicantData?.id,"sfsdfsdf")
    // get getalldocs list data
    const getalldocs = await dataProvider.getOne("getalldocs", {
      id: record?.id || applicantData?.id,
    });
    if (getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) {
      return navigation("/landloard/docview", {
        state: { getalldocs: getalldocs?.data?.data, record },
      });
    }
    navigation("/ats/docview", {
      state: { getalldocs: getalldocs?.data?.data, record },
    });
  };


  const onClickViewSecondaryApp = async (event, applicantData) => {
    // get getalldocs list data
    const getSecondaryApp = await dataProvider.getOne("getSecondaryApp", {
      id: record?.Trnid,
    });
  

    navigation("/ats/secondaryAppView" , {
        state: { getSecondaryApp: getSecondaryApp?.data?.data, record },
      }
    );
  };
  let proof_of_id_section: any = null;
  let proof_of_ssn_section: any = null;
  let proof_of_income_section: any = null;
  let other_income_section: any = null;
  let bank_section: any = null;
  const [userCloseBrowser, setUserCloseBrowser] = useState(false);
  const submitButnRef = React.useRef<HTMLButtonElement>(null);
  const userrole: any = useGetIdentity();
  const role: any = useSelector((state: RootState) => state.users.role);
  const [clickCount, setClickCount] = useState(0);

  const handleBeforeUnload = (event) => {
    // debugger;
    if (role == "Applicant" || userrole?.identity?.userrole == "Applicant") {
      const confirmationMessage =
        "You have unsaved changes. Do you want to save them before leaving?";
      event.returnValue = confirmationMessage;
      setUserCloseBrowser(true);
      return confirmationMessage;
    }
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const launchApplication = ({ username }) => {

    //debugger;
    if ((Number(record?.ApplicationCompleted) > 79))
      return '#';
    create(
      "loginFromAdminDashboard",
      {
        data: {
          email: username,
        },
      },
      {
        onSuccess: (data) => {

          window.open(data?.records?.launchUrl, "_blank");
          setState(data?.records?.launchUrl);

        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const [state, setState] = useState('#')
  const launchApplicationInvited = ({ applicantid }) => {

    create(
      "loginFromAdminDashboardInvitedApp",
      {
        data: {
          applicantid: applicantid,
        },
      },
      {
        onSuccess: (data) => {

          window.open(data?.records?.launchUrl, "_blank");
          setState(data?.records?.launchUrl);

        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const { data: getUserData } = useGetIdentity();

  if (
    IncomeAPIResponse?.isLoading &&
    bankDocLoading &&
    otherIncomeDocLoding &&
    proofOfIdsDataLoading &&
    proofOfIdsDataLoading &&
    isLoadingSubscriberConfigurations &&
    sectionPermission.isLoading
  ) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
            <h2>&nbsp; Loading...</h2>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (sectionPermission?.data?.status[3].state == 2 && proofOfIdsData) {
    proof_of_id_section = (
      <ProofOfIdForm
        record={proofOfIdsData}
        setChildFormError={setChildFormError1}
        childFormError={childFormError1}
        ref={poidRef}
        trn_id={proofOfIdsData?.id}
        index={index}
        status={sectionPermission?.data?.status[3].state}
        required={false}
      />
    );
  } else if (sectionPermission?.data?.status[3].state == 1 && proofOfIdsData) {
    proof_of_id_section = (
      <ProofOfIdForm
        record={proofOfIdsData}
        ref={poidRef}
        setChildFormError={setChildFormError1}
        childFormError={childFormError1}
        trn_id={proofOfIdsData?.id}
        index={index}
        required={true}
        status={sectionPermission?.data?.status[3].state}
      />
    );
  }

  if (sectionPermission?.data?.status[4].state == 2 && proofOfIdsData) {
    proof_of_ssn_section = (
      <SsnAccordion
        record={proofOfIdsData}
        setChildFormError={setChildFormError5}
        childFormError={childFormError5}
        ref={possn}
        Trnid={proofOfIdsData?.id}
        index={index}
        status={sectionPermission?.data?.status[4].state}
        required={false}
      />
    );
  } else if (sectionPermission?.data?.status[4].state == 1 && proofOfIdsData) {
    proof_of_ssn_section = (
      <SsnAccordion
        record={proofOfIdsData}
        ref={possn}
        setChildFormError={setChildFormError5}
        childFormError={childFormError5}
        Trnid={proofOfIdsData?.id}
        index={index}
        required={true}
        status={sectionPermission?.data?.status[4].state}
      />
    );
  }

  if (sectionPermission?.data?.status[0].state == 2 && IncomeAPIResponse.data) {
    proof_of_income_section = (
      <ProofOfEmploymentIncome
        role={userRole}
        ref={poincome}
        setChildFormError={setChildFormError2}
        childFormError={childFormError2}
        index={index}
        status={sectionPermission?.data?.status[0].state}
        IncomeAPIResponse={IncomeAPIResponse}
        LookbacksData={getlookbacks?.data}
        required={false}
        grossValue={handleGrossvalue}
      />
    );
  } else if (
    sectionPermission?.data?.status[0].state == 1 &&
    IncomeAPIResponse.data
  ) {
    proof_of_income_section = (
      <ProofOfEmploymentIncome
        role={userRole}
        index={index}
        setChildFormError={setChildFormError2}
        childFormError={childFormError2}
        IncomeAPIResponse={IncomeAPIResponse}
        LookbacksData={getlookbacks?.data}
        ref={poincome}
        required={true}
        status={sectionPermission?.data?.status[0].state}
        grossValue={handleGrossvalue}
      />
    );
  }

  if (
    sectionPermission?.data?.status[2].state == 2 &&
    otherIncomeData?.other_income_details
  ) {
    other_income_section = (
      <ProofOfNonEmploymentSection
        record={otherIncomeData?.other_income_details}
        setChildFormError={setChildFormError3}
        childFormError={childFormError3}
        ref={po_other_income}
        index={index}
        status={sectionPermission?.data?.status[2].state}
        required={false}
        trn_id={otherIncomeData.trn_id}
      />
    );
  } else if (
    sectionPermission?.data?.status[2].state == 1 &&
    otherIncomeData?.other_income_details
  ) {
    other_income_section = (
      <ProofOfNonEmploymentSection
        setChildFormError={setChildFormError3}
        childFormError={childFormError3}
        record={otherIncomeData?.other_income_details}
        ref={po_other_income}
        index={index}
        trn_id={otherIncomeData.trn_id}
        required={true}
        status={sectionPermission?.data?.status[2].state}
      />
    );
  }

  if (
    sectionPermission?.data?.status[1].state == 2 &&
    bankData?.banking_details
  ) {
    bank_section = (
      <BankStatements
        record={bankData?.banking_details}
        trn_id={bankData.trn_id}
        ref={po_banking}
        required={false}
        setChildFormError={setChildFormError4}
        childFormError={childFormError4}
        index={index}
        status={sectionPermission?.data?.status[1].state}
        isFetching={isFetching}
      />
    );
  } else if (
    sectionPermission?.data?.status[2].state == 1 &&
    bankData?.banking_details
  ) {
    bank_section = (
      <BankStatements
        record={bankData?.banking_details}
        trn_id={bankData.trn_id}
        ref={po_banking}
        setChildFormError={setChildFormError4}
        childFormError={childFormError4}
        index={index}
        required={true}
        status={sectionPermission?.data?.status[1].state}
        isFetching={isFetching}
      />
    );
  }

  const onClickViewAppHandler = async (event) => {
    const payloadData = {
      applicationNumber: record?.AppID,
      applicantID: record?.id,
    };
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    // get viewappreport list data
    const viewAppReport = await dataProvider.getOne("viewappreport", {
      id: payloadData,
    });
    window.open(`${viewAppReport?.data?.data}`, "_blank");
  };
  const handleReview = async () => {
    create(
      "updatereviewdone",
      {
        data: {
          applicationnumber: record?.AppID,
        },
      },
      {
        onSuccess: (data) => {
          // queryClient.invalidateQueries(["bankDoc"]);
          notify(`Review done successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  return (
    <>
      <ApplicantContext.Provider value={{ applicantInfo, setApplicantInfo }}>
        {/*deleted docs button  */}
        <Dialog open={deleteddocs} onClose={handleclosedeleteddocs}>
          <DialogTitle
            sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
            id="customized-dialog-title"
          >
            Deleted documents
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleclosedeleteddocs}
            sx={{
              position: "absolute",
              right: 6,
              top: 1,

              color: (theme) => theme.palette.grey[500],
            }}
          >
            {/* <span style={{fontSize:"12px"}}>Dismiss</span> */}
            <CloseIcon style={{ fontSize: "12px" }} />
          </IconButton>
          <DialogContent onClick={handleopendeleteddocs}>
            <Resource
              name="deletedDocument"
              list={GetdeleteddocumentlistList}
            />
          </DialogContent>
        </Dialog>

        <section className="main-sec">
          {appHeaderRequired && (
            <ApplicationHeader
              role={userRole}
              subscriberId={proofOfIdsData?.applicant?.SubscriberID}
              subscriberData={subscriberData}
              applicantName={`${applicantDetails?.first_name} ${applicantDetails?.last_name}`}
            />
          )}
          <div ref={exportRef} className="basic-info">
            <div className="d-flex flex-wrap mb-2">

              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">App ID.</label>
                </div>
                <span>
                  {applicantDetails?.application_id
                    ? applicantDetails?.application_id
                    : ""}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Trn ID</label>
                </div>
                <span>{trn_id ? trn_id : ""}</span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Name</label>

                  <button
                    className="print-hide"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#namechange"
                    onClick={handleOpen}
                    disabled={(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) ? true : false}
                  >
                    <img src={pencilIcon} alt="" />
                  </button>

                </div>
                <span
                  title={`${applicantDetails?.first_name} ${applicantDetails?.last_name}  `}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {`${applicantDetails?.first_name} ${applicantDetails?.last_name}  `}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Mobile Phone</label>

                  <button
                    className="print-hide"
                    type="button"
                    data-bs-toggle="modal"
                    // data-bs-target="#mobilechange"
                    data-bs-target="#namechange"
                    onClick={handleOpen}
                    disabled={(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) ? true : false}
                  >
                    <img src={pencilIcon} alt="" />
                  </button>

                </div>
                <span>
                  {applicantDetails?.mobile
                    ? applicantDetails?.country_code + applicantDetails?.mobile
                    : "NA"}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Email</label>
                  <button
                    className="print-hide"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#namechange"
                    onClick={handleOpen}
                    disabled={(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) ? true : false}
                  >
                    <img src={pencilIcon} alt="" />
                  </button>

                </div>
                <span
                  title={applicantDetails?.email}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {applicantDetails?.email}
                </span>
              </div>

              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Date</label>
                </div>
                <span>
                  {localStorage.getItem("createdDateAsPerClientRequest") ? (
                    localStorage.getItem("createdDateAsPerClientRequest")
                  ) : (
                    <DateComponent date={applicantDetails?.creataed_on} />
                  )}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">Property/Office Name</label>
                </div>
                <span>
                  {applicantDetails?.office_property_name
                    ? applicantDetails?.office_property_name
                    : ""}
                </span>
              </div>
              <div className="basic-subinfo px-2">
                <div className="info-block">
                  <label htmlFor="">User Name</label>
                </div>

                <span
                  title={applicantDetails?.UserName}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "inline-block",
                  }}
                >
                  {applicantDetails?.UserName
                    ? applicantDetails?.UserName
                    : "NA"}
                </span>
              </div>

              {(data?.ApplicationStatus === "CompletedConditionallyApproved" ||
                data?.ApplicationStatus === "Screening" ||
                data?.ApplicationStatus === "Verified" ||
                data?.ApplicationStatus === "Unverifiable" ||
                data?.ApplicationStatus ===
                "Further Documentation Required" ||
                data?.ApplicationStatus === "Completed-Approved" ||
                data?.ApplicationStatus === "Completed-Unacceptable" ||
                data?.ApplicationStatus === "Submitted" ||
                data?.ApplicationStatus !== "Review Pending") &&
                data?.ApplicationStatus !== "Invited" &&
                data?.ApplicationStatus !== "Cancelled" &&
                data?.ApplicationStatus !== "Application In Progress" &&
                data?.ApplicationStatus !== "In Progress" &&
                //  data?.ApplicationStatus !== "Archieved" &&
                userRole !== "applicant" && (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">

                      {applicantData?.Application_details?.application_type === "Application Invite" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View Report"), getUserData?.userrole)
                        ? <button
                          onClick={() => {
                            const authJson = localStorage.getItem("auth") || "";
                            let authObj = authJson ? JSON.parse(authJson) : {};
                            if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
                              const allowedKeys = ['Inbox', 'Doc Upload App'];
                              const filteredPermissions = {};

                              // Loop through the permissions and keep only allowed keys
                              for (const key in authObj.data.permissions) {
                                if (allowedKeys.includes(authObj.data.permissions[key].MenuName)) {
                                  filteredPermissions[key] = authObj.data.permissions[key];
                                }
                              }

                              // Update the object with filtered permissions
                              authObj.data.permissions = filteredPermissions;
                            }
                            if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
                              delete authObj.data.systemNotification;
                            }


                            const updatedAuthJson = JSON.stringify(authObj);

                            window.open(
                              `${ATS_API_URL}/applicant/document/printpdf?url=${window.location.protocol
                              }//${window.location.host
                              }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${trn_id +
                              `?auth=` +
                              encodeURIComponent(updatedAuthJson) +
                              `&createdDateAsPerClientRequest=` +
                              moment(applicantDetails?.creataed_on).format(
                                "MM/DD/YYYY h:mm A z"
                              )
                              }`,
                              "_blank"
                            );
                          }}
                          type="button"
                          className="p-0 mt-0 print-hide"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          style={{ lineHeight: "22px" }}
                        >
                          <BiSolidFilePdf size={16} />
                          <br />
                          <span> View/Print Result</span>
                        </button>
                        : applicantData?.Application_details?.application_type === "Doc Upload" && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "View Report"), getUserData?.userrole)
                          ? <button
                            onClick={() => {
                              const authJson = localStorage.getItem("auth") || "";
                              let authObj = authJson ? JSON.parse(authJson) : {};

                              if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
                                const allowedKeys = ['Inbox', 'Doc Upload App'];
                                const filteredPermissions = {};

                                // Loop through the permissions and keep only allowed keys
                                for (const key in authObj.data.permissions) {
                                  if (allowedKeys.includes(authObj.data.permissions[key].MenuName)) {
                                    filteredPermissions[key] = authObj.data.permissions[key];
                                  }
                                }

                                // Update the object with filtered permissions
                                authObj.data.permissions = filteredPermissions;
                              }

                              if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
                                delete authObj.data.systemNotification;
                              }


                              const updatedAuthJson = JSON.stringify(authObj);

                              window.open(
                                `${ATS_API_URL}/applicant/document/printpdf?url=${window.location.protocol
                                }//${window.location.host
                                }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${trn_id +
                                `?auth=` +
                                encodeURIComponent(updatedAuthJson) +
                                `&createdDateAsPerClientRequest=` +
                                moment(applicantDetails?.creataed_on).format(
                                  "MM/DD/YYYY h:mm A z"
                                )
                                }`,
                                "_blank"
                              );
                            }}
                            type="button"
                            className="p-0 mt-0 print-hide"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            style={{ lineHeight: "22px" }}
                          >
                            <BiSolidFilePdf size={16} />
                            <br />
                            <span> View/Print Result</span>
                          </button>
                          : applicantData?.Application_details?.application_type === "Guest Card" && isSuperAdmin(checkModuleAccess(permissions, "Guest Card App", "View Report"), getUserData?.userrole)
                            ? <button
                              onClick={() => {
                                const authJson = localStorage.getItem("auth") || "";
                                let authObj = authJson ? JSON.parse(authJson) : {};
                                if (authObj.data && authObj.data.hasOwnProperty('permissions')) {
                                  const allowedKeys = ['Inbox', 'Doc Upload App'];
                                  const filteredPermissions = {};

                                  // Loop through the permissions and keep only allowed keys
                                  for (const key in authObj.data.permissions) {
                                    if (allowedKeys.includes(authObj.data.permissions[key].MenuName)) {
                                      filteredPermissions[key] = authObj.data.permissions[key];
                                    }
                                  }

                                  // Update the object with filtered permissions
                                  authObj.data.permissions = filteredPermissions;
                                }
                                if (authObj.data && authObj.data.hasOwnProperty('systemNotification')) {
                                  delete authObj.data.systemNotification;
                                }


                                const updatedAuthJson = JSON.stringify(authObj);

                                window.open(
                                  `${ATS_API_URL}/applicant/document/printpdf?url=${window.location.protocol
                                  }//${window.location.host
                                  }/admin/docupload_admin/Office/11/TestRealEstateCompany-Philadelphia/NuEF07JC8UuAluc0k-r7Yg/${trn_id +
                                  `?auth=` +
                                  encodeURIComponent(updatedAuthJson) +
                                  `&createdDateAsPerClientRequest=` +
                                  moment(applicantDetails?.creataed_on).format(
                                    "MM/DD/YYYY h:mm A z"
                                  )
                                  }`,
                                  "_blank"
                                );
                              }}
                              type="button"
                              className="p-0 mt-0 print-hide"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              style={{ lineHeight: "22px" }}
                            >
                              <BiSolidFilePdf size={16} />
                              <br />
                              <span> View/Print Result</span>
                            </button> : ""
                      }

                    </div>
                  </div>
                )}


              {/* {data?.SecondaryApp && data?.EmailStatus &&(
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">

                      <button
                        type="button"
                        className="p-0 mt-0"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(event) => onClickViewSecondaryApp(event, applicantData)}
                        style={{ lineHeight: "22px" }}
                      >
                       
                        <CheckIcon/>
                        <br/>
                        <span>View 2ND APP</span>
                      </button>
                    </div>
                  </div>
                )} */}



              {applicantData?.Application_details?.application_type !== "Guest Card" &&
                userRole !== "applicant" &&
                // getDownloadDocumentsMessage?.message ==="Documents Uploaded" && 
                (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">
                      <button
                        type="button"
                        className="p-0 mt-0 print-hide"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          downloadZip(record?.id || id);
                        }}
                        style={{ lineHeight: "22px" }}
                        disabled={isDownloading} // Add a disabled state
                      >
                        {isDownloading ? ( // Add a loader
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress size="25px" />
                          </Box>
                        ) : (
                          <>
                            <BiSolidFileArchive size={16} /> <br />
                            <span> Download Documents</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                )}

              {applicantData?.Application_details?.application_type !=
                "Doc Upload" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                  <div className="basic-subinfo px-2 print-hide">
                    <div className="modal-sm-button">
                      <ShowNotesModal record={record} />
                    </div>
                  </div>
                )}

              {isSuperAdmin(checkModuleAccess(permissions, "Inbox", "Screening Report"), getUserData?.userrole) && applicantData?.Application_details?.application_type ==
                "Application Invite" &&
                userRole !== "applicant" &&
                (applicantData?.Application_details?.application_status == 14 ||
                  applicantData?.Application_details?.application_status ==
                  13 ||
                  applicantData?.Application_details?.application_status == 4 ||
                  applicantData?.Application_details?.application_status ==
                  15) && (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">
                      <button
                        type="button"
                        className="p-0 mt-0"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(event) => onClickScreeningReport(event, data)}
                        style={{ lineHeight: "22px" }}
                      >
                        {/* <LibraryBooksIcon size={16}/> <br /> */}
                        <FaBook size={16} /> <br />
                        <span>Screening Report</span>
                      </button>
                    </div>
                  </div>
                )}
              {appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View Adverse Letter"), getUserData?.userrole) &&
                data?.ApplicationStatus !== "Invited" &&
                data?.ApplicationStatus !== "Application In Progress" &&
                data?.ApplicationStatus !== "Submitted" &&
                data?.ApplicationStatus !== "Review Pending" &&
                data?.ApplicationStatus !== "Cancelled" &&
                data?.ApplicationStatus !== "Completed-Approved" &&
                data?.ApplicationStatus !== "Archived" &&
                data?.ApplicationStatus !== "Screening" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">
                      <button
                        onClick={() => { navigate('/ats/adverseletter', { state: { SubscriberID: id, ...data, applicantId: record?.id } }) }}
                        type="button"
                        className="p-0 mt-0 print-hide"
                        style={{ lineHeight: "22px" }}
                      >
                        <FaRegNoteSticky style={{ fontSize: "16px" }} />
                        <br />
                        <span>View Adverse Letter</span>
                      </button>
                    </div>
                  </div>
                )}

              <div className="basic-subinfo px-2">
                <div className="modal-sm-button">
                  {isSuperAdmin(checkModuleAccess(permissions, "View Docs", ""), getUserData?.userrole) && userRole !== "applicant" &&  (
                    <button
                      type="button"
                      className="p-0 mt-0"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={(event) => onClickDocsHandler(event, applicantData)}
                      style={{ lineHeight: "22px" }}
                    >
                      <img src={img1} alt="" /> <br />
                      <span>View Docs</span>
                    </button>
                  )}
                </div>
              </div>

              {

                userRole !== "applicant" &&
                record?.ApplicantStatus !== "Cancelled" &&
                record?.ApplicantStatus !== "Archieved" &&
                record?.ApplicantStatus == "Submitted" &&
                appType == "1" && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View Application"), getUserData?.userrole) &&

                (
                  <div className="basic-subinfo px-2">
                    <div className="modal-sm-button">

                      <button
                        type="button"
                        className="p-0 mt-0"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        disabled={isLoadingViewApp}
                        onClick={(event) => onClickViewAppHandler(event)}
                        style={{ lineHeight: "22px" }}
                      >
                        {isLoadingViewApp ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress size="25px" />
                          </Box>
                        ) : (
                          <>
                            <PictureAsPdfIcon /> <br />
                            <span>View App</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>

                )
              }
              {/* </>
              )} */}
              {userRole != "applicant" && !(getUserData?.userrole == typesofUsers.landlordThirdPartyAgent) && (
                <div className="basic-subinfo px-2 print-hide">
                  <div className="modal-sm-button">
                    <AuditlogModal
                      record={{
                        AppID: applicantDetails?.application_id,
                        id,
                        trn_id: record?.Trnid,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="info-toggle-wrap">
            <div className="subheaders">
              {proofOfIdsData?.applicant?.thirdPartyAppName != null && (
                <>
                  {userRole !== "applicant" && (
                    <div>
                      {proofOfIdsData?.applicant?.interactionType == "Received"
                        ? " Received from "
                        : " Sent to "}
                      {proofOfIdsData?.applicant?.thirdPartyAppName}
                    </div>
                  )}
                </>
              )}
              {appType == "1" && (
                <>
                  {" "}
                  {!(Number(record?.ApplicationCompleted) > 79) && (
                    <div className="alert alert-danger" role="alert">
                      App Status:'{record?.ApplicantStatus}'.
                      {location?.state?.isAppCreatedUsingNet &&
                        "This app has been created using an older system and is currently in view-only mode."}
                      {!location?.state?.isAppCreatedUsingNet && (
                        <>
                          {" "}
                          It is currently in view-only mode.{" "}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              record.ApplicantStatus == "Invited"
                                ? launchApplicationInvited({
                                  applicantid: record.id,
                                })
                                : launchApplication({
                                  username: record.UserName || record.Email,
                                });
                            }}
                            href="#"
                            className="alert-link"
                          >
                            Click here
                          </a>


                          <span> to open the application.</span>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>


            {!toggleDetails && isOpeningForDashboard && (
              <PersonalDetailsAccordion Trnid={record?.Trnid} />
            )}


            {
              roletype === "docupload_applicant"
                ? proof_of_id_section
                : getUserData?.userrole === typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Identification Tab", "View Proof of Identity"), getUserData?.userrole)
                  ? proof_of_id_section
                  : (applicantData?.Application_details?.application_type == "Application Invite" || appType == "1") && getUserData?.userrole != typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View PII"), getUserData?.userrole)
                    ? proof_of_id_section
                    : (applicantData?.Application_details?.application_type == "Doc Upload" || appType == "2") && getUserData?.userrole != typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "View PII"), getUserData?.userrole)
                      ? proof_of_id_section
                      : (applicantData?.Application_details?.application_type == "Guest Card" || appType == "3") && getUserData?.userrole != typesofUsers?.landlordThirdPartyAgent
                        ? proof_of_id_section
                        : ""

            }



            {roletype === "docupload_applicant"
              ? proof_of_ssn_section
              : ((!toggleDetails && isOpeningForDashboard) || data?.appInDAReviewQueue) && (getUserData?.userrole === typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Identification Tab", "View SSN"), getUserData?.userrole))
                ? proof_of_ssn_section //<SsnAccordion Trnid={record?.Trnid} />
                : (applicantData?.Application_details?.application_type == "Application Invite" || appType == "1") && getUserData?.userrole != typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Inbox", "View PII"), getUserData?.userrole)
                  ? proof_of_ssn_section
                  : (applicantData?.Application_details?.application_type == "Doc Upload" || appType == "2") && getUserData?.userrole != typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Doc Upload App", "View PII"), getUserData?.userrole)
                    ? proof_of_ssn_section
                    : ""
            }

            {!toggleDetails && isOpeningForDashboard && isSuperAdmin(checkModuleAccess(permissions, "Identification Tab", "View Address"), getUserData?.userrole) &&
              (
                <AddressAccordion Trnid={record?.Trnid} />
              )}
            {!toggleDetails && isOpeningForDashboard && (
              <>
                {/* <div className="subheaders">
                  <h6 className="ms-1">Applicant Details</h6>
                </div> */}
                <ApplicantDetailsAccordion Trnid={record?.Trnid} />
              </>
            )}


            {!isGuestCard && <div className="subheaders"></div>}
            {getUserData?.userrole === typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Income & Banking Tab", "View Proof of Employment"), getUserData?.userrole)
              ? proof_of_income_section
              : !isGuestCard
                ? proof_of_income_section
                : ""
            }
            {getUserData?.userrole === typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Income & Banking Tab", "View Other Income"), getUserData?.userrole)
              ? other_income_section
              : !isGuestCard
                ? other_income_section
                : ""
            }
            {getUserData?.userrole === typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Income & Banking Tab", "View Banking Information"), getUserData?.userrole)
              ? bank_section
              : !isGuestCard
                ? bank_section
                : ""
            }
            {getUserData?.userrole === typesofUsers?.landlordThirdPartyAgent && isSuperAdmin(checkModuleAccess(permissions, "Income & Banking Tab", "View Other Docs"), getUserData?.userrole)
              ? <OtherDocuments Trnid={record?.Trnid} />
              : !isGuestCard
                ? <OtherDocuments Trnid={record?.Trnid} />
                : ""
            }
          </div>
          {data?.appInDAReviewQueue && (
            <button
              className="btn btn-info me-md-2"
              type="submit"
              style={{
                backgroundColor: "rgb(45, 195, 232)",
                color: "white",
                padding: "5px 8px 5px 8px",
                marginLeft: "5px",
                fontFamily: "OpenSans-Bold",
                border: "0",
                marginBottom: 10,
              }}
              onClick={handleReview}
            >
              Review Done
            </button>
          )}


          <br />
          <br />
          {((appType !== "1" &&
            userRole === "Docuverus Super Admin" &&
            applicantData?.Application_details?.application_status == 5) ||
            ((appType == "2" || appType == "3") &&
              userRole === "Docuverus Super Admin") ||
            (userRole !== "applicant" && appType !== "1")) && !data?.appInDAReviewQueue ? (
            <FormProvider {...methods}>
          
              <form onSubmit={methods.handleSubmit(handle_Admin_Submit)}>
                <div className="print-hide">
                  <div className="row">
                  { userRole == "Docuverus Super Admin" && 
                    <div className="col-md-3 col-sm-12">

                   
                      <SelectInput
                        label="Application Status"
                        source={`application_status`}
                        // placeholder={applicantStatusKey}
                        options={
                          appType == "1"
                            ? [
                              { name: "Review Pending", value: "5" },
                            ]
                            : userRole !== "Docuverus Super Admin"
                              ? [{ name: "Review Pending", value: "5" }]
                              : [
                                { name: "Verified", value: "13" },
                                {
                                  name: "Further Documentation Required",
                                  value: "14",
                                },
                                { name: "Unverifiable", value: "15" },
                              ]
                        }
                        required={false}
                        extraClass={"application_status_style"}
                      />
                    </div>}

                    <div className="col-md-9 col-sm-12">
                      <div className="gap-2 d-sm-flex align-items-center">
                        <button
                          className="btn btn-primary me-md-2"
                          type="submit"
                          style={{
                            backgroundColor: "rgb(45, 195, 232)",
                            color: "white",
                            padding: "5px 8px 5px 8px",
                            fontFamily: "OpenSans-Bold",
                            border: "0",
                            marginTop: 25
                          }}
                        >
                          Submit
                        </button>

                        {appFooterRequired && (
                          <>
                            {userRole == "Docuverus Super Admin" && (
                              <AlertDialogTable trn_id={trn_id} />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </FormProvider>
          ) : (
            <></>
          )}

          {appFooterRequired && (
            <>
              {userRole !== "applicant" && (
                <div className="d-flex justify-content-between align-items-end">
                  <div></div>
                </div>
              )}
              {userRole === "applicant" && (
                <footer className="footer-custom">
                  <div className="container">
                    <div className="d-flex justify-content-end">

                      {totalApplicationCount > 1 && <> <button
                        disabled={index === 0}
                        type="button"
                        ref={submitButnRef}
                        className="modal-blue-btn"
                        onClick={
                          navigateToPrevTab
                        }
                        style={{
                          padding: "8px 18px",
                          marginRight: "10px",
                          backgroundColor: index === 0 ? "lightgrey" : "rgb(45, 195, 232)"
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                        </svg>
                        <span style={{ fontFamily: "OpenSans-Bold" }}>Previous</span>
                      </button>
                        <button
                          disabled={(index + 1) === totalApplicationCount}
                          type="button"
                          ref={submitButnRef}
                          className="modal-blue-btn"
                          onClick={handleFormSubmitNext}
                          style={{
                            padding: "8px 18px",
                            marginRight: "10px",
                            backgroundColor: (index + 1) === totalApplicationCount ? "lightgrey" : "rgb(45, 195, 232)"
                          }}
                        >
                          <span style={{ fontFamily: "OpenSans-Bold" }}>Next</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                            style={{ marginLeft: "10px" }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                            />
                          </svg>
                        </button></>}
                      <button
                        disabled={(index + 1) !== totalApplicationCount}
                        type="button"
                        ref={submitButnRef}
                        className="modal-blue-btn"

                        onClick={
                          handleFormSubmit
                        }
                        style={{
                          padding: "8px 18px",
                          marginRight: "10px",
                          backgroundColor: (index + 1) !== totalApplicationCount ? "lightgrey" : "rgb(45, 195, 232)"
                        }}
                      >
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.6665 6.61463L10.7945 0.485962L11.7378 1.42863L4.6665 8.49996L0.423828 4.2573L1.3665 3.31463L4.6665 6.61463Z"
                            fill="white"
                          />
                        </svg>
                        <span style={{ fontFamily: "OpenSans-Bold" }}>Submit </span>
                      </button>
                    </div>
                  </div>
                </footer>
              )}
              <Confirm
                isOpen={userCloseBrowser && role == "applicant"}
                sx={{
                  "& .RaConfirm-iconPaddingStyle": { display: "none" },
                }}
                title="Do you want to submit the application?"
                content={
                  "If an application is not submitted, it will not be processed."
                }
                onConfirm={() => {
                  setUserCloseBrowser(false);
                  submitButnRef?.current?.click();
                }}
                onClose={() => {
                  setUserCloseBrowser(false);
                }}
              />
            </>
          )}
          {roletype != "docupload_admin" &&
            roletype !== "docupload_applicant" &&
            appType === "1" && <GrayStripeBar data={data} />}
        </section>
        <NameChangeModal
          trn_id={proofOfIdsData?.id}
          first_name={applicantDetails?.first_name}
          last_name={applicantDetails?.last_name}
          mobile={applicantDetails?.mobile}
          email={applicantDetails?.email}
          country_code={applicantDetails?.country_code}
          fetchRecord={() => refetch()}
          // state for open dialog
          handleClose={handleClose}
          open={open}
        />
      </ApplicantContext.Provider>
    </>
  );
};

export default DocumentAnalysisShow;
